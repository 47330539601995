const cookieBanner = () => {
    const cookieBannerElement = document.querySelector('.cookieBanner');
    const cookieBannerClose = cookieBannerElement.querySelector('.close');

    const getCookie = name => {
        return document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=');
            return parts[0] === name ? decodeURIComponent(parts[1]) : r;
        }, '');
    };

    const setCookie = () => {
        document.cookie = 'cookieBanner=closed';
    };

    const isCookieSet = () => getCookie('cookieBanner');

    const showCookieBanner = () => {
        cookieBannerElement.style.display = 'block';
    };

    const hideCookieBanner = () => {
        cookieBannerElement.style.display = 'none';
    };

    if (!isCookieSet()) {
        showCookieBanner();
        cookieBannerClose.addEventListener('click', () => {
            hideCookieBanner();
            setCookie();
        });
    }
};

cookieBanner();
